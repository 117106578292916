export const imageTags = [
  "nature",
  "sunset",
  "beach",
  "mountains",
  "forest",
  "cityscape",
  "portrait",
  "wildlife",

];


//   export const  groupData= [
//     {"value": 1, "label": "One"},
//     {"value": 2, "label": "Two"},
//     {"value": 3, "label": "Three"},
//     {"value": 4, "label": "Four"},
//     {"value": 5, "label": "Five"},
//     {"value": 6, "label": "Six"},
//     {"value": 7, "label": "Seven"},
//     {"value": 8, "label": "Eight"},
//     {"value": 9, "label": "Nine"},
//     {"value": 10, "label": "Ten"},
//     {"value": 11, "label": "Eleven"},
//     {"value": 12, "label": "Twelve"}
// ]

export const groupData = [
  // {"id": 1, "name": "One"},
  // {"id": 2, "name": "Two"},
  { "value": 3, "label": "KS3" },
  { "value": 4, "label": "KS4" },
  // { "value": 5, "label": "KS5" },
  // {"id": 6, "name": "Six"},
  // {"id": 7, "name": "Seven"},
  // {"id": 8, "name": "Eight"},
  // {"id": 9, "name": "Nine"},
  // {"id": 10, "name": "Ten"},
  // {"id": 11, "name": "Eleven"},
  // {"id": 12, "name": "Twelve"}
]

export const specificUser = [
  { "value": "Student", "label": "Student" },
  { "value": "Parent", "label": "Parent" },
]




export const GENDER_TYPE=[

  { "value": "Male", "label": "MALE" },
  { "value": "Female", "label": "FEMALE" },
  { "value": "Other", "label": "OTHER" },
  { "value": "Prefer not to say", "label": "PREFER" },
]




export const groupDataStudent = [
  // {"id": 1, "name": "One"},
  // {"id": 2, "name": "Two"},
  { "value": 3, "label": "KS3" },
  { "value": 4, "label": "KS4" },
  { "value": "All", "label": "All" },
  // {"id": 6, "name": "Six"},
  // {"id": 7, "name": "Seven"},
  // {"id": 8, "name": "Eight"},
  // {"id": 9, "name": "Nine"},
  // {"id": 10, "name": "Ten"},
  // {"id": 11, "name": "Eleven"},
  // {"id": 12, "name": "Twelve"}
]

export const SchoolTypeData = [
  { "value": "School/College/Organisation", "label": "School/College/Organisation" },
  { "value": "Sponsor", "label": "Sponsor" },
  { "value": "Seller", "label": "Seller" },
]





export const sensitiveDataTypes = [
  { value: "Suicide", label: "Suicide" },
  { value: "Eating disorders", label: "Eating disorders" },
  { value: "Self-harm or body hatred", label: "Self-harm or body hatred" },
  { value: "Addiction", label: "Addiction" },
  { value: "Gender dysphoria involving body hatred or self-harm", label: "Gender dysphoria involving body hatred or self-harm" },



  { value: "Abuse", label: "Abuse" },
  { value: "Rape", label: "Rape" },
  { value: "Severe or graphic violence", label: "Severe or graphic violence" },
  { value: "Murder or genocide", label: "Murder or genocide" },
  { value: "Child abuse, paedophilia, incest or child death", label: "Child abuse, paedophilia, incest or child death" },
  { value: "Incest", label: "Incest" },
  { value: "Paedophilia", label: "Paedophilia" },
  { value: "Child death", label: "Child death" },





  { value: "Abduction or kidnapping", label: "Abduction or kidnapping" },
  { value: "Human trafficking or forced prostitution", label: "Human trafficking or forced prostitution" },
  { value: "Racism or hate speech", label: "Racism or hate speech" },
  { value: "Stillbirth, miscarriage or traumatic childbirth", label: "Stillbirth, miscarriage or traumatic childbirth" },
  { value: "Colonialism, slavery and intergenerational trauma", label: "Colonialism, slavery and intergenerational trauma" },
  { value: "Severe mental distress or illness, psychosis or trauma", label: "Severe mental distress or illness, psychosis or trauma" },
  { value: "Severe debt", label: "Severe debt" },
  { value: "Animal cruelty", label: "Animal cruelty" },



  { value: "Death or severe illness", label: "Death or severe illness" },
  { value: "Mental illness", label: "Mental illness" },
  { value: "Divorce or family separation", label: "Divorce or family separation" },
  { value: "Common phobias - Spiders", label: "Common phobias - Spiders" },
  { value: "Common phobias - Snakes", label: "Common phobias - Snakes" },


  { value: "Common phobias - Needles", label: "Common phobias - Needles" },
  { value: "Gender dysphoria not involving body hatred or self-harm", label: "Gender dysphoria not involving body hatred or self-harm" },
  { value: "Disordered Eating", label: "Disordered Eating" },
  { value: "Death", label: "Death" },
  { value: "Violence", label: "Violence" },
  { value: "Drugs", label: "Drugs" }


];

